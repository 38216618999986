<template>
	<div class="external-member text-center">
		<h2 class="mt-16 grey--text">如果还有“企业外部人员”想要了解项目进展，或者需要参与协作支持 可以将他们加入项目哦~ <a>了解详情</a></h2>
		<v-btn class="mt-8 white--text" dense color="#2879FF">立即添加</v-btn>
		<h4 class="mt-8 grey--text">外部联系人不占用企业名额，不限制人数；如您邀请的“外部联系人”，其所在公司购买了tita，我们将返给您订单额10%的佣金哦~</h4>
	</div>
</template>

<script>
export default {
  name: 'ItemExternalMember'
}
</script>